export interface Breakpoint {
    name: string;
    width: number;
}

export class Breakpoints extends EventTarget {

    public currentBreakpoint: Breakpoint = Breakpoints.getCurrentBreakpoint();

    public static EVENT_RESIZE = 'breakpoint.resize';

    public static BREAKPOINTS: Breakpoint[] = [
        {
            name: 'small',
            width: 0,
        },
        {
            name: 'mediumsmall',
            width: 480,
        },
        {
            name: 'medium',
            width: 640,
        },
        {
            name: 'mediumlarge',
            width: 768,
        },
        {
            name: 'large',
            width: 1024,
        },
        {
            name: 'xlarge',
            width: 1200,
        },
        {
            name: 'xxlarge',
            width: 1440,
        },
    ];

    constructor() {
        super();

        window.removeEventListener('resize', this.onResize);
        window.addEventListener('resize', this.onResize);
    }

    protected onResize = (e: Event): void => {
        this.refresh();
    };

    public refresh(): void  {
        const breakpoint = Breakpoints.getCurrentBreakpoint();

        if (!(this.currentBreakpoint.name === breakpoint.name)) {
            this.currentBreakpoint = breakpoint;

            this.triggerEvent();
        }
    }

    public triggerEvent (): void {
        const event = new CustomEvent(Breakpoints.EVENT_RESIZE, {
            detail: this.currentBreakpoint,
        });

        this.dispatchEvent(event);
    }

    public static getCurrentBreakpoint(): Breakpoint {
        const width = window.innerWidth;

        return [...Breakpoints.BREAKPOINTS].reverse().find((item) => {
            return item.width < width;
        }) || Breakpoints.BREAKPOINTS[0];
    }

    public static getBreakpointByName(name: string): Breakpoint {
        return Breakpoints.BREAKPOINTS.find((item) => {
            return item.name === name;
        }) || Breakpoints.BREAKPOINTS[0];
    }

    public static isBreakpoint(name: string): boolean {
        const width = window.innerWidth;

        return !![...Breakpoints.BREAKPOINTS].reverse().find((item) => {
            return item.width < width && item.name === name;
        });
    }

    public static compareBreakpoint(breakpoint: Breakpoint): number {
        const currentBreakpoint = Breakpoints.getCurrentBreakpoint();

        let out = 0;

        if (breakpoint.width > currentBreakpoint.width) {
            out = -1;
        } else if (breakpoint.width < currentBreakpoint.width) {
            out = 1;
        }

        return out;
    }

    public static compareBreakpointByName(name: string): number {
        const breakpoint = Breakpoints.getBreakpointByName(name);

        return Breakpoints.compareBreakpoint(breakpoint);
    }
}

export default new Breakpoints();
