// @ts-ignore
import * as laxGlobal from 'lax.js/src/lax.js';
import { Breakpoints } from 'ShopUiBundle/modules/breakpoints';
import { debounce } from 'ShopUiBundle/modules/debounce';

export class Lax {
    protected debouncedUpdateLaxElements: () => void;

    constructor() {
        const breakpoints = Breakpoints.BREAKPOINTS.reduce(
            (accumulator: any, breakpoint) => {
                accumulator[breakpoint.name] = breakpoint.width;
                return accumulator;
            },
            {});

        laxGlobal.setup({
            breakpoints,
        });

        this.debouncedUpdateLaxElements = debounce({
            test: () => true,
            shot: () => {
                this.updateLaxElements();
            },
            timeout: 5,
        }).bind(this);

        window.requestAnimationFrame(() => this.updateLax());
        window.addEventListener('load', () => this.update());
        window.addEventListener('resize', () => this.update());
    }

    protected updateLax(): void {
        laxGlobal.update(window.scrollY || window.pageYOffset);
        window.requestAnimationFrame(() => this.updateLax());
    }

    protected updateLaxElements(): void {
        laxGlobal.updateElements();
    }

    public update(debounced = true): void {
        if (debounced) {
            this.debouncedUpdateLaxElements();
        } else {
            this.updateLaxElements();
        }
    }

    public addElement(element: HTMLElement): void {
        laxGlobal.addElement(element);
    }

    public removeElement(element: HTMLElement): void {
        laxGlobal.removeElement(element);
    }
}

export default new Lax();
