export async function nextTick<T>(callback?: () => T, milliseconds?: number): Promise<T|void> {
    return new Promise<T|void>(
        resolve => window.setTimeout(
            () => {
                if (callback) callback();
                resolve();
            },
            milliseconds,
        ));
}
