const DEFAULT_TIMEOUT = 300;

export interface DebounceOptions<T> {
    timeout?: number;
    test: (...args: T[]) => boolean;
    shot: (...args: T[]) => void;
}

export class Debounce<T> {
    private timeoutId?: number;
    protected options: DebounceOptions<T>;

    constructor({ timeout = DEFAULT_TIMEOUT, ...rest }: DebounceOptions<T>) {
        this.options = { timeout, ...rest };
    }

    private start(...args: T[]): void {
        this.timeoutId = window.setTimeout(
            () => {
                this.options.shot(...args);
            },
            this.options.timeout,
        );
    }

    stop(): void {
        window.clearTimeout(this.timeoutId);
    }

    trigger(...args: T[]): void {
        this.stop();
        if (this.options.test(...args)) {
            this.start(...args);
        }
    }
}

export function debounce<T>(options: DebounceOptions<T>): (...args: T[]) => void {
    const debouncer = new Debounce<T>(options);
    return (...args: T[]) => debouncer.trigger(...args);
}
