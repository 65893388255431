import createBemHelper, { BemHelper, BemHelperMethods } from '../bem-helper';

/**
 * A Component is an extension of an HTMLElement.
 * It is used in Spryker Shop as base class for every components.
 */
export default abstract class Component extends HTMLElement implements BemHelperMethods {
    private isComponentMounted = false;
    private readonly bemHelper: BemHelper<Component>;

    /**
     * The name of the component.
     */
    readonly name: string;

    /**
     * The js-safe name of the component.
     */
    readonly jsName: string;

    /**
     * Creates an instance of Component.
     */
    constructor() {
        super();
        this.name = this.tagName.toLowerCase();
        this.jsName = `js-${this.name}`;
        this.bemHelper = createBemHelper(this);
    }

    protected dispatchCustomEvent(
        name: string,
        { detail = {}, bubbles = false, cancelable = false } = {},
    ): CustomEvent {
        const customEvent = new CustomEvent(name, { detail, bubbles, cancelable });
        this.dispatchEvent(customEvent);

        return customEvent;
    }

    /**
     * Automatically invoked by the application when component has to be mounted.
     *
     * Initialise the component.
     * It's invoked when DOM is completely loaded and every other webcomponent in the page has been defined.
     * @remarks
     * Use this method as initial point for your component, especially if you intend to query the DOM for
     * other webcomponents. If this is not needed, you can still use `connectedCallback()` instead for
     * a faster execution, as described by official documentation for WebComponents here:
     * {@link https://developer.mozilla.org/en-US/docs/Web/Web_Components/
     * Using_custom_elements#Using_the_lifecycle_callbacks}
     */
    mountCallback(): void {
        void 0;
    }

    connectedCallback(): void {
        if (!this.isMounted) return;

        this.mountCallback();
    }

    mount(): void {
        if (this.isMounted) return;

        this.isComponentMounted = true;
        this.mountCallback();
    }

    /**
     * Gets if the component has beed mounted already.
     */
    get isMounted(): boolean {
        return this.isComponentMounted;
    }

    /**
     * Gets if the component has beed connected already.
     */
    get isConnected(): boolean {
        return this.isConnected;
    }

    queryElement<T extends HTMLElement>(name: string, js = true): T | null {
        return this.bemHelper.queryElement(name, js);
    }

    queryElementAll<T extends HTMLElement>(name: string, js = true): T[] {
        return this.bemHelper.queryElementAll(name, js);
    }

    getElementClass(name: string, js = true): string {
        return this.bemHelper.getElementClass(name, js);
    }

    getModifierClass(name: string): string {
        return this.bemHelper.getModifierClass(name);
    }

    hasModifier(name: string): boolean {
        return this.bemHelper.hasModifier(name);
    }

    addModifier(name: string): void {
        this.bemHelper.addModifier(name);
    }

    removeModifier(name: string): void {
        this.bemHelper.removeModifier(name);
    }

    removeModifiers(modifiers: string[]): void {
        this.bemHelper.removeModifiers(modifiers);
    }

    removeAllModifiers(): void {
        this.bemHelper.removeAllModifiers();
    }

    setModifier(name: string): void {
        this.removeAllModifiers();
        this.addModifier(name);
    }
}
