export class GuuruLoader extends EventTarget {
    public static SHOW_EVENT = 'guuru-loader-show';
    public static HIDE_EVENT = 'guuru-loader-hide';

    public guuru: any = null;

    constructor() {
        super();

        this.onChatOpened = this.onChatOpened.bind(this);
        this.onChatClosed = this.onChatClosed.bind(this);
    }

    protected onChatOpened(): void {
        this.guuru?.hideChatLoader();
        this.dispatchEvent(new CustomEvent(GuuruLoader.SHOW_EVENT));
    }

    protected onChatClosed(): void {
        this.dispatchEvent(new CustomEvent(GuuruLoader.HIDE_EVENT));
    }

    public load(): void {
        if (!document.getElementById('guuru-loader')) {
            const script = document.createElement('script');
            script.defer = true;
            script.id = 'guuru-loader';
            script.src = 'https://static.guuru.com/loader/v1.0/chat.min.js';
            document.head.appendChild(script);

            document.addEventListener(
                'guuru:loaded',
                () => this.init(),
                {
                    once: true,
                },
            );
        }
    }

    protected init(): void {
        this.guuru = (window as any).Guuru();
        (window as any).guuru = this.guuru;

        this.guuru?.init({
            appId: 'rosebikes',
            openTriggers: ['#open-guuru-chat'],
            launcher: {
                displayRules: {
                    hideOnLoad: true,
                },
            },
            events: [{
                id: 'loader:chatOpened',
                handler: this.onChatOpened,
            }, {
                id: 'loader:chatClosed',
                handler: this.onChatClosed,
            }],
        });
    }

    public show(): void {
        this.dispatchEvent(new CustomEvent(GuuruLoader.SHOW_EVENT));
        this.guuru?.openChat();
    }
}

export default new GuuruLoader();
