export interface BemHelperOptions {
    name?: string;
    jsName?: string;
}

export interface BemHelperMethods {
    addModifier(name: string): void;
    hasModifier(name: string): boolean;
    setModifier(name: string): void;
    removeModifier(name: string): void;
    removeModifiers(modifiers: string[]): void;
    removeAllModifiers(): void;
    getElementClass(name: string, js?: boolean): string;
    getModifierClass(name: string): string;
    queryElement<E extends HTMLElement>(name: string, js?: boolean): E | null;
    queryElementAll<E extends HTMLElement>(name: string, js?: boolean): E[];
}

export interface BemHelper<T extends HTMLElement> extends BemHelperMethods {
    getBlock(): T;
}

class BemHelperImpl<T extends HTMLElement> implements BemHelper<T> {

    protected readonly block: T;
    protected readonly name?: string;
    protected readonly jsName?: string;

    constructor(block: T, options?: BemHelperOptions) {
        this.block = block;
        this.name = (options && options.name) || block.tagName.toLowerCase();
        this.jsName = (options && options.jsName) || `js-${this.name}`;
    }

    addModifier(name: string): void {
        this.block.classList.add(`${this.name}--${name}`);
    }

    removeModifier(name: string): void {
        this.block.classList.remove(`${this.name}--${name}`);
    }

    removeModifiers(modifiers: string[]): void {
        modifiers.forEach((className) => {
            this.block.classList.remove(`${this.name}--${className}`);
        });
    }

    removeAllModifiers(): void {
        this.block.classList.forEach((className) => {
            if (className.startsWith(`${this.name}--`)) {
                this.block.classList.remove(className);
            }
        });
    }

    queryElement<E extends HTMLElement>(name: string, js = true): E | null {
        return this.block.querySelector(this.getElementClass(name, js));
    }

    queryElementAll<E extends HTMLElement>(name: string, js = true): E[] {
        return Array.from(this.getBlock().querySelectorAll(this.getElementClass(name, js)));
    }

    getElementClass(name: string, js = true): string {
        return `.${js ? this.jsName : this.name}__${name}`;
    }

    getModifierClass(name: string): string {
        return `.${this.name}--${name}`;
    }

    getBlock(): T {
        return this.block;
    }

    hasModifier(name: string): boolean {
        return this.getBlock().classList.contains(`${this.name}--${name}`);
    }

    setModifier(name: string): void {
        this.removeAllModifiers();
        this.addModifier(name);
    }
}

export default function createBemHelper<B extends HTMLElement>(block: B, options?: BemHelperOptions): BemHelper<B> {
    return new BemHelperImpl<B>(block, options);
}
