export const TRANSITION_END_EVENTS = [
    'webkitTransitionEnd',
    'otransitionend',
    'oTransitionEnd',
    'msTransitionEnd',
    'transitionend',
];

export function removeOnTransitionEndListener(element: Element, listener: () => void): void {
    TRANSITION_END_EVENTS.forEach((eventName) => {
        element.removeEventListener(eventName, listener);
    });
}

export function addOnTransitionEndListenerOnce(element: Element, listener: () => void): void {
    const wrapper = () => {
        listener();
        removeOnTransitionEndListener(element, wrapper);
    };

    TRANSITION_END_EVENTS.forEach((eventName) => {
        element.addEventListener(eventName, wrapper);
    });
}
