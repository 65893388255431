import loadingAttributePolyfill from 'loading-attribute-polyfill';
import { debounce } from 'lodash';

export function update(): void {
    document.querySelectorAll('noscript.loading-lazy')
        .forEach(loadingAttributePolyfill.prepareElement);
}

export const updateDebounced = debounce(update, 1000 / 60, {
    leading: true,
    trailing: true,
});
